<script setup lang="ts">
const { finish: finishNuxtLoading } = useLoadingIndicator()
finishNuxtLoading()
if (process.client) {
  finishNuxtLoading()
}

const route = useRoute()

// Check for suspicious query parameters
const suspiciousPatterns = [
  /^\d+$/, // only digits
  /'\s*or\s*'/i, // contains ' or '
  /'\s*and\s*'/i, // contains ' and ',
  /example\.com/i // contains example.com (case insensitive)
]

// Check for suspicious patterns in Referer header
if (process.client) {
  const referer = document.referrer
  if (referer) {
    suspiciousPatterns.forEach((pattern) => {
      if (pattern.test(referer)) {
        console.warn('Suspicious Referer detected:', referer)
        throw createError({
          statusCode: 404,
          message: 'Requested resource not found.',
          fatal: true
        })
      }
    })
  }
}

Object.entries(route.query).forEach(([key, value]) => {
  suspiciousPatterns.forEach((pattern) => {
    if (pattern.test(key) || pattern.test(String(value))) {
      console.warn('Suspicious query parameter detected:', { key, value })
      throw createError({
        statusCode: 404,
        message: 'Requested resource not found.',
        fatal: true
      })
    }
  })
})
</script>
<template>
  <NuxtLayout>
    <div>
      <NuxtLoadingIndicator color="#262626" />
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>
