// https://developers.facebook.com/docs/meta-pixel/reference/
// https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const { $fb } = useNuxtApp()
  const castedFb = $fb as any
  console.log('$fb', castedFb)

  function setFBQUser(user: ReturnType<typeof useAuth>['data']['value']): void {
    if (runtimeConfig.public.environmentName !== 'prod') return
    console.log('fbq identify', user)

    if (!user) {
      castedFb.disable()
      return
    }

    castedFb.setUserData({
      em: user.email,
      fn: user.first_name,
      ln: user.last_name,
      ph: user.secret_part?.phone,
      external_id: user.id_slug
    })
    castedFb.enable()
  }

  function fbqTrack(event: string, params: Record<string, string | number>): void {
    if (runtimeConfig.public.environmentName !== 'prod') return
    console.log('fbq track', event, params)
    castedFb.track(event, params)
  }

  const { data: user } = useAuth()
  setFBQUser(user.value)

  return {
    provide: {
      setFBQUser,
      fbqTrack
    }
  }
})
